import React from 'react';
import { Box, Card, Typography, List, ListItem, ListItemText, Avatar } from '@mui/material';
import { Line as ChartJSLine, Doughnut as ChartJSDoughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Legend as ChartJS_Legend,
  Tooltip as ChartJS_Tooltip,
  LineElement,
  PointElement,
  Filler,
  ArcElement,
} from 'chart.js';

import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend as RechartsLegend } from 'recharts';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  Title,
  ChartJS_Legend,
  ChartJS_Tooltip,
  LineElement,
  PointElement,
  Filler,
  ArcElement
);

// Sample Data
const dailyStatsData = {
  labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  datasets: [
    {
      label: 'Activities',
      data: [10, 15, 20, 18, 25, 22, 30],
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 2,
    },
  ],
};

const dailyDoughnutData = {
  labels: ['Posts', 'Comments', 'Messages', 'Appointments'],
  datasets: [
    {
      label: 'Activity Distribution',
      data: [40, 25, 20, 15],
      backgroundColor: [
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)',
        'rgba(75, 192, 192, 0.6)',
        'rgba(153, 102, 255, 0.6)',
      ],
      borderWidth: 1,
    },
  ],
};

const weeklyData = [
  { day: 'Mon', Posts: 5, Comments: 8, Messages: 2, Appointments: 1 },
  { day: 'Tue', Posts: 10, Comments: 6, Messages: 3, Appointments: 2 },
  { day: 'Wed', Posts: 7, Comments: 9, Messages: 4, Appointments: 3 },
  { day: 'Thu', Posts: 8, Comments: 5, Messages: 5, Appointments: 2 },
  { day: 'Fri', Posts: 12, Comments: 7, Messages: 6, Appointments: 4 },
  { day: 'Sat', Posts: 9, Comments: 11, Messages: 7, Appointments: 3 },
];

const SupDailyActivity = () => {
  return (
    <Box sx={{ padding: 3, display: 'grid', gap: 2, gridTemplateColumns: 'repeat(6, 1fr)',backgroundColor:"#fcf8ff" }}>
      {/* Summary Cards */}
      <Card sx={{ gridColumn: 'span 2',padding: 2,backgroundColor:"#eeeeee" }}>
        <Typography variant="h6">User Joined Today : 43</Typography>
        <Typography variant="body1">Students: 13</Typography>
        <Typography variant="body1">Teachers: 20</Typography>
        <Typography variant="body1">Professionals: 10</Typography>
      </Card>
      <Card sx={{ gridColumn: 'span 2',padding: 2 ,backgroundColor:"#f5f5f5"}}>
        <Typography variant="h6">Registered Institute Today</Typography>
        <Typography variant="h4">12</Typography>
      </Card>
      <Card sx={{ gridColumn: 'span 2',padding: 2,backgroundColor:"#fff5f5" }}>
        <Typography variant="h6">Question</Typography>
        <Typography variant="h4">38</Typography>
      </Card>
      <Card sx={{ gridColumn: 'span 2',padding: 2,backgroundColor:"#f7fff5" }}>
        <Typography variant="h6">Answers</Typography>
        <Typography variant="h4">38</Typography>
      </Card>
       <Card sx={{ gridColumn: 'span 1',padding: 2,backgroundColor:"#f5fffe" }}>
        <Typography variant="h6">Jobs : 34</Typography>
        {/* <Typography variant="h4">38</Typography> */}
        <Typography variant="body1">Applied : 13</Typography>
      </Card>
       <Card sx={{  gridColumn: 'span 1',padding: 2 ,backgroundColor:"#f5f6ff"}}>
        <Typography variant="h6">Comments</Typography>
        <Typography variant="h4">38</Typography>
      </Card>
      <Card sx={{ gridColumn: 'span 1',padding: 2,backgroundColor:"#fff5fe" }}>
        <Typography variant="h6">Events</Typography>
        <Typography variant="h4">342</Typography>
      </Card>

      <Card sx={{  gridColumn: 'span 1',padding: 2 }}>
        <Typography variant="h6">Appointments Scheduled</Typography>
        <Typography variant="h4">8</Typography>
      </Card>

      {/* Daily Activity Line Chart */}
      <Card sx={{ gridColumn: 'span 4', padding: 2 }}>
        <Typography variant="h6">Daily Activity Trends</Typography>
        <ChartJSLine data={dailyStatsData} />
      </Card>

      {/* Activity Distribution (Doughnut Chart) */}
      <Card sx={{ gridColumn: 'span 2', padding: 2 }}>
        <Typography variant="h6">Activity Distribution</Typography>
        <ChartJSDoughnut data={dailyDoughnutData} />
      </Card>

      {/* Weekly Activity Stats */}
      <Card sx={{ gridColumn: 'span 4', padding: 2 }}>
        <Typography variant="h6">Weekly Activity Overview</Typography>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={weeklyData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <RechartsTooltip />
            <RechartsLegend />
            <Line type="monotone" dataKey="Posts" stroke="#FF5733" strokeWidth={2} />
            <Line type="monotone" dataKey="Comments" stroke="#33FF57" strokeWidth={2} />
            <Line type="monotone" dataKey="Messages" stroke="#3357FF" strokeWidth={2} />
            <Line type="monotone" dataKey="Appointments" stroke="#FFC300" strokeWidth={2} />
          </LineChart>
        </ResponsiveContainer>
      </Card>

      {/* Recent Activities */}
      <Card sx={{  gridColumn: 'span 2',padding: 2 }}>
        <Typography variant="h6">Recent Activities</Typography>
        <List>
          <ListItem>
            <ListItemText primary="John created a new post" secondary="1 hour ago" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Anna commented on a post" secondary="30 minutes ago" />
          </ListItem>
          <ListItem>
            <ListItemText primary="David scheduled an appointment" secondary="2 hours ago" />
          </ListItem>
        </List>
      </Card>
    </Box>
  );
};

export default SupDailyActivity;
